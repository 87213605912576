





















import InteractionLib from "@/utils/interaction.lib";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SearchRes extends Vue {
  @Prop({ default: () => [] }) private readonly dataList!: any;

  created() {}
  handlerJumpList(item: number) {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${item}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${item}` },
      query: { time: String(this.getNow()) },
    });
  }
  getNow(): number {
    return Math.ceil(Date.now() / 1000);
  }
}
