<template>
  <div class="wrap">
    <InputB
      class="inputb"
      @clear="clear"
      @search="search"
      :inputcontent="inputcontent"
      @inputChange="inputChange"
    />
    <div class="oval" v-if="searchState">
      <div class="flex-row">
        <div style="font-weight: 800">搜索历史</div>
        <img
          @click="clearhis"
          style="width: 20px; height: 20px"
          src="https://cdn.vgn.cn/static/Official-website/rublish.png"
          alt=""
        />
      </div>
      <div class="content">
        <div
          class="history-box"
          v-for="(item, index) in histlist"
          :key="index"
          @click="handlersearch(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div style="margin: 10px auto" v-else>
      <div v-if="dataList.length != 0">
        <List
          v-model="loading"
          finished="finished"
          finished-text="没有更多了"
          @load="load"
          :immediate-check="false"
        >
          <SearchRes class="SearchRes" :dataList="dataList" />
        </List>
      </div>
      <div v-else>
        <Empty class="empty" tip="没有相关结果，换个关键词试试～" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Icon } from "vant";
import Empty from "@/components/EmptyBox.vue";
import InputB from "@/views/search/components/input.vue";
import SearchRes from "@/views/search/components/SearchRes.vue";
import SearchApi from "@/api/search.api";
import { List } from "vant";
@Component({
  components: {
    Icon,
    InputB,
    SearchRes,
    List,
    Empty,
  },
})
export default class SearchContent extends Vue {
  histlist = [];
  searchState = true;
  dataList = [];
  loading = false;
  finished = false;
  inputcontent = "";
  page = 1;
  created() {
    if (localStorage.getItem("searList")) {
      this.histlist = JSON.parse(localStorage.getItem("searList")) || [];
    }
  }
  handlerJumpList(item) {
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${item}` },
      query: { time: String(this.getNow()) },
    });
  }
  getNow() {
    return Math.ceil(Date.now() / 1000);
  }
  inputChange(content) {
    this.inputcontent = content;
  }
  search(e) {
    this.finished = false;
    let content = e.content;
    if (content.length == "") {
      return;
    }
    if (this.histlist.length == 15) {
      this.histlist.pop();
    }
    let hisindex = this.histlist.indexOf(content);
    let arr = [];
    this.searchState = false;
    if (content.length > 10) {
      content = content.slice(0, 10);
      content += "...";
    }

    if (hisindex != -1) {
      this.histlist.forEach((item, index) => {
        if (index == hisindex) {
        } else {
          arr.push(item);
        }
      });
      arr.unshift(content);
      this.histlist = arr;
    } else {
      this.histlist.unshift(content);
    }
    localStorage.setItem("searList", JSON.stringify(this.histlist));
    this.dataList = e.res;
    if (this.dataList.length <= 10) {
      this.finished = true;
    }
  }
  async load() {
    let res = await SearchApi.test({
      keyword: this.inputcontent,
      page: ++this.page,
    });
    if (res.length < 10) {
      this.finished = true;
    }
    res.forEach((item) => {
      this.dataList.push(item);
    });
  }
  clear() {
    this.searchState = true;
  }
  clearhis() {
    localStorage.setItem("searList", JSON.stringify([]));
    this.histlist = [];
  }
  async handlersearch(keyword) {
    this.finished = false;
    this.dataList = [];
    this.inputcontent = keyword;
    this.searchState = false;
    const res = await SearchApi.test({
      keyword,
    });
    this.dataList = res;
    if (this.dataList.length <= 10) {
      this.finished = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  top: 80px;
}
.SearchRes {
  width: 95%;
  margin: 0 auto;
}
.content {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 12px;
}
.history-box {
  background: #f6f6f6;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 14px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.oval {
  height: 700px;
  width: 100%;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  margin-top: 10px;
  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    box-sizing: border-box;
  }
}
</style>
